@import url("https://fonts.googleapis.com/css?family=Caveat");

html {
  height: 100%;
}

html,
body {
  overflow-x: hidden;
}

body {
  margin: 0;
  height: 100%;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ant-modal {
  width: 80%;
  padding-bottom: 0px;
}

.ant-layout,
.ant-layout-footer {
  font-weight: 400;
}

.bjs-powered-by {
  display: none !important;
}

.ant-dropdown-menu {
  padding: 0px;
}

.project-dropdown .ant-dropdown-menu {
  position: relative;
  top: 5px;
}

.ant-layout-header {
  /* background-color: white; */
  border-bottom: 1px solid #e8e8e8;
  line-height: 48px !important;
  padding: 0px;
}

.ant-top-menu {
  line-height: 38px !important;
  text-align: center;
}

.ant-table {
  background-color: transparent;
}

.ant-table-beawre table {
  border-collapse: separate;
}

.ant-table-beawre-comments tr th,
.ant-table-beawre tr th,
.ant-table.beawre-dark tr th {
  border: none;
  text-transform: uppercase !important;
  font-size: 12px !important;
}

.ant-table-beawre-comments tr th,
.ant-table-beawre tr th {
  color: #90a8be !important;
}

.ant-table.beawre-dark tr th {
  color: #f8f8f8 !important;
}

.ant-table-beawre .ant-table-tbody tr .show-on-hover {
  margin-left: 5px;
  display: none;
}

.ant-table-beawre .ant-table-tbody tr:hover .show-on-hover {
  display: inline;
}

.ant-table-beawre .ant-table-tbody tr:first-child td:first-child {
  border-top-left-radius: 5px;
}

.ant-table-beawre .ant-table-tbody tr:first-child td:last-child {
  border-top-right-radius: 5px;
}

.ant-table-beawre .ant-table-tbody tr:last-child td:first-child {
  border-bottom-left-radius: 5px;
}

.ant-table-beawre .ant-table-tbody tr:last-child td:last-child {
  border-bottom-right-radius: 5px;
}

.ant-table-beawre .ant-table-tbody tr:hover > td {
  background-color: #f0f0f0;
  cursor: pointer;
}

.ant-table-beawre .ant-table-tbody tr.ant-table-expanded-row > td {
  box-shadow: 0 0 0 1px #e8e8e8 inset;
  /* background-color: #f9f9f9 !important; */
  border-radius: 4px;
}

.ant-table-beawre .ant-table-tbody tr.ant-table-expanded-row:hover {
  box-shadow: 0 0 0 1px #e8e8e8 inset;
  /* background-color: #fcfcfc; */
  transform: none;
}

.ant-table-beawre .ant-table-tbody tr.ant-table-expanded-row:hover td {
  background-color: transparent;
}

.ant-table-beawre .ant-table-tobdy td {
  padding: 0 8px;
  border: none;
}

.ant-table-beawre .ant-table-tbody h4 {
  color: #90a8be;
}

.ant-table-beawre-comments table {
  border-collapse: separate;
}

.ant-table-beawre-comments tr th {
  border: none;
  text-transform: uppercase;
  font-size: 12px;
  color: #90a8be;
}

.ant-table-beawre-comments .ant-table-tbody tr .show-on-hover {
  margin-left: 5px;
  display: none;
}

.ant-table-beawre-comments .ant-table-tbody tr:hover .show-on-hover {
  display: inline;
}

.ant-table-beawre-comments .ant-table-tbody tr:first-child td:first-child {
  border-top-left-radius: 5px;
}

.ant-table-beawre-comments .ant-table-tbody tr:first-child td:last-child {
  border-top-right-radius: 5px;
}

.ant-table-beawre-comments .ant-table-tbody tr:last-child td:first-child {
  border-bottom-left-radius: 5px;
}

.ant-table-beawre-comments .ant-table-tbody tr:last-child td:last-child {
  border-bottom-right-radius: 5px;
}

.ant-table-beawre-comments .ant-table-tbody tr:hover > td {
  cursor: pointer;
}

.ant-table-beawre-comments .ant-table-tbody tr.ant-table-expanded-row > td {
  box-shadow: 0 0 0 1px #e8e8e8 inset;
  border-radius: 4px;
}

.ant-table-beawre-comments .ant-table-tbody tr.ant-table-expanded-row:hover {
  box-shadow: 0 0 0 1px #e8e8e8 inset;
  transform: none;
}

.ant-table-beawre-comments .ant-table-tbody tr.ant-table-expanded-row:hover td {
  background-color: transparent;
}

.ant-table-beawre-comments .ant-table-tobdy td {
  padding: 0 8px;
  border: none;
}

.ant-table-beawre-comments .ant-table-tbody h4 {
  color: #90a8be;
}

.ant-menu-header-icon {
  font-size: 1.6em !important;
  position: relative;
  top: 4px;
}

.ant-menu-top-title {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.9em;
}

.ant-table-column-title-custom {
  text-transform: uppercase;
  font-size: 0.8em;
  color: #90a8be;
  font-weight: bolder;
  background-color: transparent !important;
  cursor: pointer;
}

.ant-table-column-title-underline {
  text-transform: uppercase;
  font-size: 0.8em;
  text-decoration: underline;
  color: #90a8be;
  font-weight: bolder;
  background-color: transparent !important;
  cursor: pointer;
}

.ant-table-beawre-comments .ant-table-thead > tr > th,
.ant-table-beawre .ant-table-thead > tr > th {
  background: #f7f8f9 !important;
}

.ant-table-beawre-dark .ant-table-thead > tr > th {
  background: #000000 !important;
}

.ant-table-beawre-black .ant-table-thead > tr > th {
  background: #141414 !important;
}

.ant-table-white .ant-table-thead > tr > th {
  background: #ffffff !important;
}

.ant-table-body {
  overflow-y: auto !important;
}

.ant-table-row-level-1 {
  background-color: #d3d3d359;
}

h1 {
  font-size: 1.7em !important;
  font-weight: 100 !important;
  display: inline-block !important;
  margin-left: 24px;
  margin-top: 12px !important;
  position: relative !important;
  text-transform: uppercase;
}

.h2-title {
  font-size: 2em;
  font-weight: 100;
  display: inline-block;
  margin-left: 20px;
  position: relative;
  text-transform: uppercase;
}

.h2-title:before {
  content: "";
  position: absolute;
  width: 60%;
  height: 20px;
  bottom: -20px;
  left: 0%;
  border-bottom: 8px solid #edcd1f;
  margin-bottom: 20px;
}

.h3-title {
  font-size: 1.5em;
  font-weight: 100;
  display: inline-block;
  margin-left: 20px;
  position: relative;
  text-transform: uppercase;
}

.h3-title:before {
  content: "";
  position: absolute;
  width: 60%;
  height: 15px;
  bottom: -20px;
  left: 0%;
  border-bottom: 5px solid #edcd1f;
  margin-bottom: 20px;
}

.ant-btn {
  font-weight: 500 !important;
  text-shadow: none !important;
}

.ant-btn > span {
  padding: 0px 2px;
}

.ant-card {
  border-radius: 4px !important;
}

.ant-btn-link {
  color: #778da9;
}

.ant-btn-primary {
  box-shadow: 0 3px 6px rgba(119, 141, 169, 0.5);
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  background-color: rgba(0, 0, 0, 0.025);
}

.sider {
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
  min-height: 100vh;
}

.ant-card-body {
  height: 100%;
}

.ant-statistic-title {
  color: #90a8be !important;
}

.ant-statistic-title i {
  font-size: 1.8em;
}

.ant-statistic-title p {
  margin-bottom: 0 !important;
}

.ant-statistic-content {
  font-weight: 100 !important;
}

.ant-statistic-content-suffix {
  color: rgba(0, 0, 0, 0.4);
}

.ant-anchor-wrapper {
  background-color: transparent;
}

.ant-drawer-wrapper-body {
  height: 90%;
}

.koni-custom-node {
  width: 45px;
}

.qb-lite .group--header:hover .group--header .group--drag-handler,
.qb-lite .group--header:hover .group--header .group--actions,
.qb-lite .group--header:not(:hover) .group--drag-handler,
.qb-lite .group--header:not(:hover) .group--actions,
.qb-lite .rule_group:not(:hover) .group--drag-handler,
.qb-lite .rule_group:not(:hover) .group--actions {
  opacity: 100;
}

.qb-lite .group--conjunctions .ant-btn:not(.ant-btn-primary) {
  transition: padding 0.2s;
}

.qb-lite .group--header:hover .group--header .group--conjunctions .ant-btn:not(.ant-btn-primary),
.qb-lite .group--header:not(:hover) .group--conjunctions .ant-btn:not(.ant-btn-primary),
.qb-lite .rule_group:not(:hover) .group--conjunctions .ant-btn:not(.ant-btn-primary) {
  width: inherit;
  padding: inherit;
  opacity: 100;
}

.qb-lite .widget--valuesrc,
.qb-lite .rule--drag-handler,
.qb-lite .rule--header {
  transition: opacity 0.2s;
}

.qb-lite .rule:hover .rule .widget--valuesrc,
.qb-lite .rule:hover .rule .rule--drag-handler,
.qb-lite .rule:hover .rule .rule--header,
.qb-lite .rule:not(:hover) .widget--valuesrc,
.qb-lite .rule:not(:hover) .rule--drag-handler,
.qb-lite .rule:not(:hover) .rule--header {
  opacity: 100;
}

.qb-lite.qb-dragging .widget--valuesrc,
.qb-lite.qb-dragging .rule--drag-handler,
.qb-lite.qb-dragging .rule--header,
.qb-lite.qb-dragging .group--drag-handler,
.qb-lite.qb-dragging .group--actions {
  opacity: 100 !important;
}

#bowtieEditorId > div {
  background: none !important;
}

#bowtieEditorId {
  background-color: rgba(255, 255, 255, 0.7);
}

#bowtieEditorId > div > div {
  padding: 0px;
  border: 25px solid white;
}

.customSearch .ant-input-affix-wrapper {
  border-color: #d9d9d9;
}

.customSearch .anticon svg {
  color: #535252;
  margin: 4px 0px 2px 0px;
  box-sizing: border-box;
}

.ant-input-search .ant-input-search-button {
  height: 31.5px;
}

.documentsModal .ant-modal-content {
  background-color: transparent !important;
}

.customSearch .ant-btn-primary {
  background-color: #f1f1f112;
  border-color: #d9d9d9;
  box-shadow: 0px 0px 0px transparent;
}

.rule {
  background-color: transparent !important;
}

.group {
  background-color: transparent !important;
}

.ant-layout-content {
  margin-top: 0px !important;
}

.ant-menu-horizontal {
  border-bottom: 1px solid transparent;
}

.button-animation span {
  max-width: 0;
  -webkit-transition: max-width 250ms;
  transition: max-width 250ms ease-out;
  overflow: hidden;
  transition-delay: 0.2s;
}

.button-animation:focus span {
  max-width: 10rem;
  transition-delay: 0s;
}

.tag-animation span {
  max-width: 0;
  -webkit-transition: max-width 0.25s;
  transition: max-width 0.25s;
  overflow: hidden;
  transition-delay: 0.2s;
}

.tag-animation:hover span {
  max-width: 20rem;
  transition-delay: 0s;
}

.menu-animation span {
  max-width: 0;
  -webkit-transition: max-width 350ms;
  transition: max-width 350ms;
  overflow: hidden;
  transition-delay: 0.1s;
}

.menu-animation:hover span {
  max-width: 20rem;
  transition-delay: 0s;
}

.ant-dropdown-menu-submenu-arrow {
  display: none;
}

button .ant-tag {
  background: transparent;
  border: 1px solid transparent;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  margin: 0px;
  padding: 0px;
}

.high-res-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.low-res-container {
  display: flex;
  flex-direction: column;
  min-width: 220px;
}

.low-res {
  margin-bottom: 5px;
  border-radius: 3px;
}

.high-res {
  border-radius: 0px;
}

.high-res span {
  padding: 0px;
}

.high-res-leftedge {
  border-radius: 5px 0px 0px 5px;
}

.high-res-rightedge {
  border-radius: 0px 5px 5px 0px;
}

/* .predictions tr:nth-child(even) {
  background-color: #fafafa;
} */

/* .predictions tr:hover {
  background-color: #F0F0F0;
} */

/* .predictions thead {
  background-color: #fafafa;
} */

.predictions td h4 {
  margin-top: 6px;
}

/* .ant-btn > .anticon {
  line-height: none;
} */

/* .ant-btn {
  color: red !important;
} */

.ant-modal-confirm .ant-modal-body {
  padding: 6px 6px 6px;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 0px;
}

.adm-list {
  --header-font-size: 18px;
  --font-size: 14px;
}

.adm-form {
  ---border-top: var(--border-top: solid 0px transparent);
  ---border-bottom: var(--border-bottom: solid 0px transparent);
}

.adm-form .adm-list.adm-list {
  border-top: solid 1px transparent !important;
  border-bottom: solid 1px transparent !important;
  --padding-left: 0px;
  --padding-right: 0px;
}

/* .adm-list {
  --font-size: var(--adm-font-size-7);
} */

.mobile-entries .adm-list-default .adm-list-body {
  border-top: solid 1px transparent !important;
  border-bottom: solid 1px transparent !important;
}

.mobile-entries .adm-list-item-content {
  border-top: solid 1px transparent !important;
  border-bottom: solid 1px transparent !important;
}

.adm-text-area-element {
  font-size: 14px !important;
  padding: 2px 6px !important;
}

.adm-input-element {
  font-size: 14px !important;
  padding: 2px 6px !important;
}

/* .menu-drawer .ant-drawer-content {
  margin-top: 48px;
} */
/* 
.evidenceList .ant-list-bordered {
  border: 1px solid #fff;
  background-color: #fff;
}

.configList .ant-list-bordered {
  border: 1px solid #fff;
  background-color: rgb(252, 252, 252);
}

.ant-dropdown-menu-item-active {
  background-color: #d7d3d3 !important;
} */

.adm-checkbox-content {
  font-size: 14px !important;
}

.adm-form-item.adm-form-item-vertical .adm-form-item-label {
  font-size: 14px !important;
  margin-bottom: 12px !important;
}

.adm-radio-content {
  font-size: 14px !important;
}

.adm-form {
  --border-inner: solid 1px transparent;
}

.dnd {
  height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: transparent;
}

.ant-tag-geekblue {
  color: #1d39c4 !important;
}

.ant-form-item {
  margin-bottom: 8px;
}

.commentMgmt .ant-comment-inner {
  padding: 0;
}

/* .comment-row-color {
  background-color: #fafafa;
}

.comment-row-colorwhite {
  background-color: #fff;
} */

.ant-comment-nested {
  margin-left: 0px;
}

.bpmn-bowtie-Drawer .ant-drawer-content {
  margin-top: 0px;
}

.qb-lite .group--header:hover .group--header .group--drag-handler,
.qb-lite .group--header:hover .group--header .group--actions,
.qb-lite .group--header:not(:hover) .group--drag-handler,
.qb-lite .group--header:not(:hover) .group--actions,
.qb-lite .rule_group:not(:hover) .group--drag-handler,
.qb-lite .rule_group:not(:hover) .group--actions {
  opacity: 1 !important;
}

.qb-lite .group--header:hover .group--header .group--conjunctions .ant-btn:not(.ant-btn-primary),
.qb-lite .group--header:not(:hover) .group--conjunctions .ant-btn:not(.ant-btn-primary),
.qb-lite .rule_group:not(:hover) .group--conjunctions .ant-btn:not(.ant-btn-primary) {
  opacity: 1 !important;
  width: 100% !important;
  padding: 0px 10px !important;
}

.adm-dropdown-item {
  justify-content: flex-start !important;
}

.adm-dropdown-item .adm-dropdown-item-title {
  padding-top: 0px !important;
  margin-bottom: 12px !important;
}

.adm-dropdown-item .adm-dropdown-item-title {
  font-size: 14px !important;
}

.ant-spin-dot-item {
  background-color: #58255e !important;
}

.collect-automate-breadcrumbs .ant-breadcrumb li:last-child {
  color: transparent !important;
}

.barcode-scanning-active {
  background: transparent !important;
  opacity: 0 !important;
}

.adm-floating-bubble-button {
  background: #58255e !important;
}

.react-transform-wrapper {
  width: 100% !important;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.ant-divider-horizontal {
  margin: 12px 0px;
}

#next {
  display: none !important;
}
